<template>
  <div class="container-inbound-waiting">
    <div class="box-title" style="display:flex;justify-content: space-between">
      <h4>
        <i class="fal fa-scanner"></i>
        {{ $t('Sidebar.ຄັງພັດສະດຸສາຂາ') }} ({{ $t('pages.ລໍຖ້າຍິງເຂົ້າ') }})
        <span class="countTotal"></span>
        <vs-button
            :loading="isExporting"
            @click="exportShipmentOrdersToExcel"
            color="whatsapp" style="position: absolute;right: 0;top: 50px;">
          Export Excel
        </vs-button>
      </h4>
    </div>
    <div class="box" v-if="isRoleService === 'service'">
      <div class="columns">
        <div class="column is-12">
          <div class="control">
            <label class="label">{{ $t('ລາຍການOrderພັດສະດຸ.ເລືອກເບິ່ງຕາມສາຂາ') }}:</label>
            <div class="field">
              <div class="control">
                <model-list-select
                    style="z-index: 9999999999"
                    :list="branches"
                    v-model="serviceSelectedBranch"
                    :placeholder="$t('ລາຍການOrderພັດສະດຸ.ເລືອກສາຂາ')"
                    option-text="name"
                    option-value="id"
                    selectedItem="name">
                </model-list-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ") }}</label>
              <input
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
                  class="input"
                  :placeholder="`${$t('ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ')}...`"
              >
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຈາກວັນທີ") }}:</label>
            <date-picker
                type="date"
                :clearable="false"
                name="start_date"
                v-model="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຫາວັນທີ") }}:</label>
            <date-picker
                type="date"
                :clearable="false"
                name="end_date"
                v-model="end_date"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label>{{ $t('common.select_sender') }} :</label>
            <div class="group-radio">
              <vs-radio val="1" v-model="selectedItem">
                <label>{{ $t('common.ສາຂາ') }} :</label>
              </vs-radio>
              <vs-radio val="2" v-model="selectedItem">
                <label>{{ $t('common.ສູນຄັດແຍກ') }} :</label>
              </vs-radio>
            </div>
          </div>
        </div>
        <div v-if="selectedItem == 1" class="column is-4">
          <div class="control">
            <label class="label">{{ $t('common.ເລືອກສາຂາ') }}:</label>
            <div class="field">
              <div class="control">
                <model-list-select
                    :list="branches"
                    v-model="selectedBranch"
                    :placeholder="$t('common.ເລືອກສາຂາ')"
                    option-text="name"
                    option-value="id"
                    selectedItem="name">
                </model-list-select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4" v-else>
          <div class="control" v-if="selectedItem == 2">
            <label class="label">{{ $t('common.ເລືອກສູນຄັດແຍກ') }}:</label>
            <model-list-select
                :list="distributionCenters"
                v-model="selectDistributionCenter"
                :placeholder="$t('common.ເລືອກສູນຄັດແຍກ')"
                option-text="name"
                option-value="id"
                selectedItem="name">
            </model-list-select>
          </div>
        </div>
        <div class="column is-4">
          <div class="field" style="padding-top: 4px;">
            <div class="control">
              <vs-button
                  ref="button" flat
                  @click="FetchData"
                  class="form-btn-loading"
                  :loading="isLoading">
                {{ $t("ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ") }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ລຳດັບ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ເລກທີບິນ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ສາຂາຕົ້ນທາງ') }}</vs-th>
            <vs-th></vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ສາຂາປາຍທາງ') }}</vs-th>
            <vs-th>{{ $t('ລາຍການOrderພັດສະດຸ.ລາຍລະອຽດບິນ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ຜູ້ຍິງພັດສະດຸ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ວັນເວລາຍິງ') }}</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item,index) in parcelUnInbound" :key="index">
            <vs-td style="text-align: right;">
              <div class="group-icon">
                <vs-button circle icon flat @click="openNewTab(item)">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ item.parcel_in_shipment.parcel_bill_number }}</vs-td>
            <vs-td>{{ item.shipment.start_branch.name }}</vs-td>
            <td style="vertical-align: middle;"><i style="color: red;font-size: 1.2rem" class="fas fa-long-arrow-alt-right"></i></td>
            <vs-td>{{ item.shipment.end_branch.name }}</vs-td>
            <vs-td>{{ item.latest_tracking }}</vs-td>
            <vs-td>{{ item.created_by_user.name }}</vs-td>
            <vs-td>{{ item.shipment.start_date_actual }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>

    </div>
  </div>
</template>

<script>
import Loading from "@views/Loading";
import DatePicker from "vue2-datepicker";
import pagination from "@/Plugins/Paginations/CusorPagination";
import {ModelListSelect} from 'vue-search-select';
import moment from "moment";
import * as helper from "@/Helpers/GetValue";
import {FormatDate} from "@/Helpers/Format";
import {mapGetters} from "vuex";

export default {
  name: "Parcel-Inbound-Waiting",
  components: {
    DatePicker,
    ModelListSelect,
    Loading,
    pagination,
  },
  data() {
    return {
      selectedItem: "",
      branches: [],
      selectedBranch: false,
      selectDistributionCenter: false,
      isLoading: true,
      distributionCenters: [],
      start_date: moment().subtract(3, 'days').toDate(),
      end_date: new Date(),
      searchItem: "",
      parcelUnInbound: [],
      // Pagination
      page: 1,
      max: 10,
      offset: 10,
      count: 0,
      pagination: {},
      per_page: 10,
      limit_page: 10,
      getBranchId: "",
      isRoleService: "",
      serviceSelectedBranch: "",
      isExporting: false,
    }
  },
  computed: {
    ...mapGetters(['get_branch_id', 'checkIsServiceRole']),
    selectedDestinationName() {
      if (this.selectedItem === 1) {
        const id = this.selectedBranch;
        if (id && id !== "all") {
          return this.$t('common.ສາຂາ') + " " + (this.branches.filter((item) => item.id == id)[0] || {}).name
        }
        return this.$t('common.ທັງໝົດ');
      }

      const id = this.selectDistributionCenter;
      if (id && id !== "all") {
        return this.$t('common.ສູນຄັດແຍກ') + " " + (this.distributionCenters.filter((item) => item.id == id)[0] || {}).name
      }
      return this.$t('common.ທັງໝົດ');

    },
  },
  watch: {
    'start_date': function () {
      this.FetchData();
    },
    'end_date': function () {
      this.FetchData();
    },
    'selectDistributionCenter': function () {
      this.selectedBranch = null;
      this.FetchData();
    },
    'selectedBranch': function () {
      this.selectDistributionCenter = null;
      this.FetchData();
    },
    'serviceSelectedBranch': function (val) {
      this.getBranchId = val;
      this.FetchData();
    }

  },
  methods: {
    exportShipmentOrdersToExcel() {
      if (this.isExporting) {
        return;
      }
      this.isExporting = true;
      /// Preparing data
      const body = {
        shipment_pay_type: "",
        userId: null,
        end_branch: null,
        sort_order: "desc",
        force_id: "true",
        is_only_count: "false",
        branch_id: this.getBranchId ? this.getBranchId : "all",
        sender_branch_id: this.selectedBranch ? this.selectedBranch : null,
        sender_distribution_center_id: this.selectDistributionCenter ? this.selectDistributionCenter : null,
        status: "un-inbound",
        query: this.searchItem,
        start_date: moment(this.start_date).format('YYYY-MM-DD 00:00:00.000'),
        end_date: moment(this.end_date).format('YYYY-MM-DD 23:59:59.000'),
        cursor: null,
        per_page: 12,
      };
      this.$http.post('v1/auth/branches/shipments/exports/orders/checking/shipment-holding', body,
          {responseType: 'blob'}).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download',
            `Exported - Shipments - ${this.$t(`pages.ລໍຖ້າຍິງເຂົ້າ`)}, ${this.selectedDestinationName} - Date at ${FormatDate(body.start_date)}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.isExporting = false;
      }).catch(() => {
        this.isExporting = false;
      })
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    },
    FetchListBranches() {
      const branchId = this.get_branch_id;
      this.$http.get(`v1/listing/branches?type=&include=excluded&outbound=true&request_branch_id=${branchId}`).then((res) => {
        this.branches = res.data;
        this.branches.unshift({
          id: "",
          name: this.$t("common.ທັງໝົດ"),
        });
      })
    },
    FetchDistributionCenter() {
      this.$http.get('v1/listing/distribution_centers').then(res => {
        if (res) {
          this.distributionCenters = res.data;
        }
      }).catch(() => {
      });
    },
    countData() {
      return this.fetchItemsResponse({isCount: true}).then((res) => {
        if (!res.data.error) {
          this.$emit('dataCountUpdated', {
            'count': res.data.count,
            type: 'un_inbound_count'
          })
        }
      }).catch(() => {
      });
    },
    FetchData() {
      this.isLoading = true;
      this.fetchItemsResponse().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.parcelUnInbound = res.data.data;
            this.pagination = res.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
      /// Counting data
      this.countData()
    },
    fetchItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('v1/auth/branches/shipments/orders/tracking/holding-shipments', {
        params: {
          shipment_pay_type: "",
          userId: null,
          end_branch: null,
          sort_order: "desc",
          force_id: true,
          is_only_count: options.isCount ?? false,
          branch_id: this.getBranchId ? this.getBranchId : "all",
          sender_branch_id: this.selectedBranch ? this.selectedBranch : null,
          sender_distribution_center_id: this.selectDistributionCenter ? this.selectDistributionCenter : null,
          status: "un-inbound",
          query: this.searchItem,
          start_date: moment(this.start_date).format('YYYY-MM-DD 00:00:00.000'),
          end_date: moment(this.end_date).format('YYYY-MM-DD 23:59:59.000'),
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    openNewTab(item) {
      const Url = this.$router.resolve({name: 'branch.list.order.detail.inbound',
        params:{id: item.shipment_id}, query: {force: 'true'}});
      window.open(Url.href);
    },
  },
  created() {
    this.getBranchId = this.get_branch_id;
    /// Fetch first lists
    this.FetchListBranches();
    this.FetchDistributionCenter();

    if (this.getBranchId) {
      this.selectedItem = 1;
      this.selectedBranch = this.getBranchId;
    }
    this.isRoleService = this.checkIsServiceRole;
  }
}
</script>

<style scoped lang="scss">
.group-radio {
  display: flex;
  margin-top: 2px;
}
</style>
