<template>
  <div class="container">
    <div class="tabs is-centered" style="background-color: #FFFFFF;">
      <ul style="margin: 0">
        <li :class="{'is-active': activeIndex === index}"
            v-for="(tab,index) in tabsData" :key="index" @click="changeTabs(index)">
          <a>
            <span class="label-input">{{ $t(`pages.${tab.name}`) }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div class="tab-item" v-if="activeIndex === 0">
        <Inbound/>
      </div>
      <div class="tab-item" v-if="activeIndex === 1">
        <unInbound/>
      </div>
      <div class="tab-item" v-if="activeIndex === 2">
        <Outbound/>
      </div>
      <div class="tab-item" v-if="activeIndex === 3">
        <unOutbound/>
      </div>
    </div>
  </div>
</template>

<script>
import Inbound from "@views/SuperAdmin/Parcel/ParcelDistributionCenter/TabDistributionStatus/Inbound";
import Outbound from "@views/SuperAdmin/Parcel/ParcelDistributionCenter/TabDistributionStatus/Outbound";
import unInbound from "@views/SuperAdmin/Parcel/ParcelDistributionCenter/TabDistributionStatus/Un-Inbound";
import unOutbound from "@views/SuperAdmin/Parcel/ParcelDistributionCenter/TabDistributionStatus/Un-Outbound";

export default {
  name: "Index",
  components: {
    Inbound,
    Outbound,
    unInbound,
    unOutbound,
  },
  data() {
    return {
      activeIndex: 0,
      tabsData: [
        {
          name: "ຍິງເຄື່ອງເຂົ້າ"
        },
        {
          name: "ລໍຖ້າຍິງເຂົ້າ"
        },
        {
          name: "ຍິງເຄື່ອງອອກ"
        },
        {
          name: "ລໍຖ້າຍິງເຄື່ອງອອກ"
        }
      ],
      checkUser: {
        distribution_center: {},
      },
    }
  },
  methods: {
    changeTabs(index) {
      this.activeIndex = index;
    },
  },
  watch: {
    'checkUser': function (val) {
      if (val && val.distribution_center) {
        this.$store.commit("set_DistributionCenter", val.distribution_center === null ? "all" : val.distribution_center.id)
      }
    }
  },
  created() {
    this.$http.get(`list-my-profile`).then((res) => {
      this.checkUser = res.data;
      if (this.checkUser && this.checkUser.distribution_center) {
        this.$store.commit("set_DistributionCenter", this.checkUser.distribution_center.id)
      } else {
        this.$store.commit("set_DistributionCenter", "all")
      }
    }).catch(() => {
    })
    if (this.$can('service')) {
      this.$store.commit("set_service_role","service")
    }
  }
}
</script>

<style scoped lang="scss">
</style>
